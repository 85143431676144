import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

import passport from '../../images/passport/eddie.jpg';

const Eddie = () => {
  const title = 'About Eddie';

  const photos = useStaticQuery(graphql`
    query eddiePhotos {
      allFile(
        filter: { relativeDirectory: { eq: "eddie" } }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>

      <div className="passport">
        <img src={passport} alt="Eddie passport" />

        <div>
          <p>
            I learnt Wing Chun from Master Leung Sheung from 1969 to 1978.
            Sihing Roland and I were already very good friends during that
            period, enjoying playing soccer together as well as Wing Chun.
          </p>

          <p>
            I think Roland's "Learn Wing Chun, Learn English" project is a
            wonderful one, and I am happy that we can share the great mission of
            spreading our Wing Chun to many generations to come!
          </p>
        </div>
      </div>

      <hr />

      <h2>Photo gallery</h2>

      {photos.allFile.edges.map(({ node }) => (
        <Img fluid={node.childImageSharp.fluid} className="photo" />
      ))}
    </Layout>
  );
};

export default Eddie;
